<template>
  <div class="transactions">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Transactions'
}
</script>

<style scoped>
.transactions {
  min-height:76%;
  display: flex;
}
</style>
